import React, { Component } from "react"
import { Modal, Button, Container, Row, Col, Spinner } from "react-bootstrap"
import * as queryString from "query-string"
import Metatags from "../../components/metatags"
import { handleConfirmEmail } from "../../services/auth"
import Navbar from "../../components/navbar"
import Footer from "../../components/footer"
import ErrorModal from "../../components/error-modal"
import "../../css/multilogica-theme.scss"

class EmailVerify extends Component {
  constructor(context) {
    super(context)
    const { k } = queryString.parse(context.location.search)
    this.state = {
      pageText: "Verificando email...",
      token: k,
      spinnerVerifying: true,
      showErrorConnModal: false,
    }
  }

  componentDidMount() {
    // const jwt = localStorage.getItem("jwt")
    this.confirmEmail(this.state.token)
  }

  confirmEmail = async token => {
    if (token) {
      const jwt = localStorage.getItem("jwt")
      await handleConfirmEmail({ token: token }).then(res => {
        if (!res.isAxiosError) {
          if (res.status === 201) {
            if (jwt) {
              this.setState({
                pageText: "Email verificado.",
                spinnerVerifying: false,
              })
            } else {
              this.setState({
                pageText:
                  "Email verificado. Agora você já pode fazer login normalmente.",
                spinnerVerifying: false,
              })
            }
          }
        } else {
          this.setState({
            showErrorConnModal: true,
          })
        }
      })
    }
  }

  removeErrorConnModal = () => {
    this.setState({
      showErrorConnModal: !this.state.showErrorConnModal,
      spinnerVerifying: false
    })
  }

  render() {
    return (
      <>
        <ErrorModal
          modalErro={this.state.showErrorConnModal}
          removeErrorConnModal={this.removeErrorConnModal}
        />
        <Metatags title="" description="" url="" image="" keywords="" />
        <Navbar />
        <div className="space-50" style={{ height: "50px" }} />
        <Modal show={this.state.modalEmailRegistred}>
          <Modal.Body>
            <p>Este email já está registrado</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.removeModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.modalEmailSent}>
          <Modal.Body>
            <p>Por favor verifique seu email para confirmar sua conta</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.removeConfirmationModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
        <Container>
          <Row>
            <Col md={2} />
            <Col md={8}>
              <Container>
                <Row>
                  <Col>
                    <h4 style={{ float: "left" }}>
                      {this.state.pageText
                        ? this.state.pageText
                        : "Verificando email..."}
                    </h4>
                    <div
                      style={{
                        display: this.state.spinnerVerifying ? "block" : "none",
                        float: "left",
                        marginLeft: "6px",
                      }}
                    >
                      <Spinner animation="border" variant="secondary" />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <div className="height-40" />
        <Footer />
      </>
    )
  }
}

export default EmailVerify
